import React from "react";

function InputTodo({ todoText, onChange, onClick, disabled }) {
  const style = {
    backgroundColor: "#c7e5d9",
    width: "400px",
    height: "30px",
    padding: "8px",
    margin: "8px",
    borderRadius: "8px",
  };
  return (
    <div style={style}>
      <input
        placeholder="TODOを入力"
        value={todoText}
        onChange={onChange}
        disabled={disabled}
      />
      <button onClick={onClick} disabled={disabled}>
        追加
      </button>
    </div>
  );
}

export default InputTodo;
